import React from 'react'
import { graphql } from 'gatsby'
import { EventCard } from '../types'
import { Events as EventsPageComponent } from '../components/pages'

const Events: React.FC<Props> = ({ data, location }) => {
  const events: EventCard[] = data.allContentfulEvent.nodes
    .filter((e: EventNode) => e?.slug && e?.name && e?.image?.gatsbyImageData)
    .map((e: EventNode) => {
      return {
        slug: e.slug,
        name: e.name,
        gatsbyImageData: e.image.gatsbyImageData
      }
    })

  return <EventsPageComponent events={events} location={location} />
}

export default Events

export const query = graphql`
  query EventPageQuery {
    allContentfulEvent(sort: { fields: createdAt, order: DESC }) {
      nodes {
        slug
        name
        image {
          gatsbyImageData(layout: CONSTRAINED, height: 840)
        }
      }
    }
  }
`

interface Props {
  data: {
    allContentfulEvent: {
      nodes: EventNode[]
    }
  }
  location: any
}

interface EventNode {
  slug: string
  name: string
  description: {
    description: string
  }
  image: {
    gatsbyImageData: any
  }
}
